import { affiliateLeadAdapter } from "../data/affiliate-lead/affiliate-lead-adapter";
import {
  AffiliateLead,
  AffiliateLeadAdapter,
  AffiliateLeadParams,
  affiliateLeadService,
} from "../domain/affiliate-lead/affiliate-lead";

export const setupAffiliateLead = (affiliateLeadAdapter: AffiliateLeadAdapter): void => {
  const { areValidParams, mapParamsToLead } = affiliateLeadService();

  const extractUrlParams = (): AffiliateLeadParams => {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      utm_medium: searchParams.get("utm_medium") ?? undefined,
      utm_source: searchParams.get("utm_source") ?? undefined,
      utm_campaign: searchParams.get("utm_campaign") ?? undefined,
      utm_content: searchParams.get("utm_content") ?? undefined,
      compadoid: searchParams.get("compadoid") ?? undefined,
      clickId: searchParams.get("clickId") ?? undefined,
      transaction_id: searchParams.get("transaction_id") ?? undefined,
    };
  };

  const params = extractUrlParams();

  if (!areValidParams(params)) {
    return;
  }

  const affiliateLead = mapParamsToLead(params);
  affiliateLeadAdapter.save(affiliateLead);
};

export const useAffiliateLead = (): AffiliateLead | null => {
  const adapter = affiliateLeadAdapter();

  return adapter.get();
};
