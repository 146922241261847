import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import Button from "../../atoms/button/Button";
import RadioFieldset, {
  RadioFieldSetVariantsName,
} from "../../molecules/radio-fieldset/RadioFieldset";
import { Events, track } from "../../utils/analytics";
import useTrackPageViewed from "../../utils/hooks/useTrackPageViewed";
import { DealEventProperties } from "../deal-event-properties/domain/deal-event-properties";
import { useDealEventProperties } from "../deal-event-properties/use-cases/deal-event-properties-use-cases";
import PageStepForm from "../page-step-form/PageStepForm";
import { Result } from "../result/domain/result";
import { useResult } from "../result/use-cases/result-use-cases";
import * as styles from "./WaitingPeriod.module.scss";

interface RadioFieldSetOptions {
  title: string | JSX.Element | JSX.Element[];
  value: string;
  labelVariantClassName?: string;
}

interface WaitingPeriodFormValues {
  waitingPeriodAnswer: string | null;
  waitingPeriodCorrectAnswer: string;
}

interface Image {
  default: string;
}

const waitingPeriodFormSVG: Image =
  require("../../images/pages/results/small-print/clock.svg") as Image;

const waitingPeriodFormSVGSrc: string = waitingPeriodFormSVG.default;

const WaitingPeriod = (): JSX.Element => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const [isSelectedCorrectAnswer, setIsSelectedCorrectAnswer] = useState<boolean | null>(null);
  const [radioFieldSetOptions, setRadioFieldSetOptions] = useState<RadioFieldSetOptions[]>();
  const nextUrl = `/results/${result?.uuid}/small_print/pre_existing_conditions/`;
  const dealEventProperties: DealEventProperties | null = useDealEventProperties();

  useTrackPageViewed(Events.SMALL_PRINT_WAITING_PERIOD_VIEWED_BROWSER);

  useEffect(() => {
    setRadioFieldSetOptions(() =>
      Array.from({ length: 2 }, (_, i) => {
        return {
          title: parse(t(`small_print.waiting_period.answer_${i}`)),
          value: `${i}`,
        };
      })
    );
  }, [t]);

  const getButtonLabel = (): string => {
    return isSelectedCorrectAnswer === null
      ? t("common.cta.next")
      : isSelectedCorrectAnswer
      ? t("common.cta.correct")
      : t("common.cta.try_again");
  };

  const trackAnswer = (answer: string, isCorrect: boolean): void => {
    const properties = {
      ...dealEventProperties,
      smallPrintWaitingPeriod: {
        answer,
        isSelectedCorrectAnswer: isCorrect,
      },
    };

    track(Events.FORM_ANSWERED, properties);
  };

  const waitingPeriodFormData = {
    initialValues: {
      waitingPeriodAnswer: null,
      waitingPeriodCorrectAnswer: "0",
    },
    validationSchema: Yup.object({
      waitingPeriodAnswer: Yup.string().required(t("common.validation.required")),
    }),
    handleSubmit: (
      values: WaitingPeriodFormValues,
      { setSubmitting }: FormikHelpers<WaitingPeriodFormValues>
    ): void => {
      setSubmitting(true);

      const isCorrectAnswer: boolean =
        values.waitingPeriodAnswer === values.waitingPeriodCorrectAnswer;

      const newOptions: RadioFieldSetOptions[] | undefined = radioFieldSetOptions?.map(
        (option: RadioFieldSetOptions) => {
          if (option.value === values.waitingPeriodAnswer) {
            return {
              ...option,
              labelVariantClassName: isCorrectAnswer ? "correctAnswer" : "incorrectAnswer",
            };
          }

          return option;
        }
      );

      const finishSubmit = (): void => {
        setSubmitting(false);
        setRadioFieldSetOptions(newOptions);
        setIsSelectedCorrectAnswer(isCorrectAnswer);
        trackAnswer(
          t(`small_print.pre_existing_conditions.answer_${values.waitingPeriodAnswer}`),
          isCorrectAnswer
        );

        if (isCorrectAnswer) {
          setTimeout((): void => {
            void navigate(nextUrl);
          }, 500);
        }
      };

      setTimeout(finishSubmit, 500);
    },
    children: (props: FormikProps<WaitingPeriodFormValues>) => {
      const { isSubmitting, values, setFieldValue } = props;

      return (
        <Form className={styles.waitingPeriodForm}>
          {radioFieldSetOptions && (
            <RadioFieldset
              name={"waiting-period-question"}
              legend={"waiting-period-question"}
              initialValue={null}
              variantName={RadioFieldSetVariantsName.smallPrintPages}
              onChange={(value: string): void => {
                void setFieldValue("waitingPeriodAnswer", value);
                if (isSelectedCorrectAnswer !== null) {
                  setIsSelectedCorrectAnswer(null);
                }
              }}
              options={radioFieldSetOptions}
            />
          )}
          <Button
            type="submit"
            disabled={
              isSubmitting || !values.waitingPeriodAnswer || isSelectedCorrectAnswer === false
            }
            isLoading={isSubmitting}
            adoptionClassName={isSelectedCorrectAnswer ? styles.correctAnswer : ""}
          >
            {getButtonLabel()}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("small_print.waiting_period.title")}
      subtitle={t("small_print.waiting_period.subtitle")}
      image={waitingPeriodFormSVGSrc}
      formData={waitingPeriodFormData}
      adoptionClassName={styles.pageStepForm}
    />
  );
};

export default WaitingPeriod;
