import { navigate } from "gatsby";
import { useEffect } from "react";

import Meta from "../organisms/meta/meta";
import { getCurrentURLSearchParams, getMobileOS } from "../utils/browser-features";

const Page = (): JSX.Element => {
  const currentURLSearchParams = getCurrentURLSearchParams();
  const country = currentURLSearchParams?.get("country") || "es";

  useEffect(() => {
    void navigate(
      getMobileOS() === "iOS"
        ? `https://apps.apple.com/${country}/app/barkibu/id1514417760`
        : `https://play.google.com/store/apps/details?id=com.barkibu.app&hl=${country}`
    );
  }, []);

  return (
    <Meta
      title={"Download app"}
      lang={country}
      slug={undefined}
      description={undefined}
      bodyClass={""}
      disableTranslations={false}
      noIndex={true}
      alternateVersions={undefined}
    />
  );
};

export default Page;
