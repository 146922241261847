// extracted by mini-css-extract-plugin
export var editButton = "InfoCard-module--editButton--43876";
export var editLink = "InfoCard-module--editLink--2203d";
export var editLinkDisabled = "InfoCard-module--editLinkDisabled--ff8c4";
export var errorMessage = "InfoCard-module--errorMessage--b886a";
export var footnote = "InfoCard-module--footnote--87f81";
export var icon = "InfoCard-module--icon--ec961";
export var infoCard = "InfoCard-module--infoCard--8ad5a";
export var item = "InfoCard-module--item--da542";
export var petParent = "InfoCard-module--pet-parent--b7fa5";
export var phoneCountryCode = "InfoCard-module--phone-country-code--41c10";
export var phoneNumber = "InfoCard-module--phone-number--788e3";
export var title = "InfoCard-module--title--66011";