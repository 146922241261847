import { LinksObject } from "../utils/hooks/useTransformLinks";

export const countryDisclaimerLinks: Record<string, LinksObject> = {
  DEFAULT: {},
  DE: {
    prevention: { text: "document.prevention.text", link: "document.prevention.link" },
  },
  IT: {
    healthIpid: { text: "document.health.ipid.text", link: "document.health.ipid.link" },
    mup: { text: "document.mup.text", link: "document.mup.link" },
    additionalHealthIpid: {
      text: "document.additional_health_ipid.text",
      link: "document.additional_health_ipid.link",
    },
  },
  PT: {
    healthIpid: { text: "document.health.ipid.text", link: "document.health.ipid.link" },
    preNote: { text: "document.pre_note.text", link: "document.pre_note.link" },
  },
};
