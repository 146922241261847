import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import * as styles from "./SelectableProductsDisclaimer.module.scss";

const SelectableProductsDisclaimer = ({ documentLinks }): ReactElement => {
  const { t } = useTranslation();
  const documents = documentLinks
    .map((documentLink) => `<a href="${documentLink.link}">${documentLink.text}</a>`)
    .join(", ");

  const note = `<a href=${t("document.note.link")}>${t("document.note.text")}</a>`;
  const preNote = `<a href=${t("document.pre_note.link")}>${t("document.pre_note.text")}</a>`;

  return (
    <span
      className={styles.disclaimer}
      dangerouslySetInnerHTML={{
        __html: t("disclaimer.info", { documents, note, preNote }),
      }}
    ></span>
  );
};

export default SelectableProductsDisclaimer;
