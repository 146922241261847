import { useTranslation } from "gatsby-plugin-react-i18next";

interface Link {
  text: string;
  link: string;
}

export type LinksObject = Record<string, Link>;

export const useTransformLinks = (linksObj: LinksObject | undefined): Record<string, string> => {
  const { t } = useTranslation();

  if (!linksObj) {
    return {};
  }

  return Object.entries(linksObj).reduce((acc, [key, { text, link }]) => {
    acc[key] = `<a href="${t(link)}">${t(text)}</a>`;

    return acc;
  }, {} as Record<string, string>);
};
