import { Dispatch, SetStateAction, useCallback } from "react";

import { useGetPaymentUrl } from "../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../settings/countries";
import { PageId } from "../../../../settings/pages";
import { getWindow } from "../../../../utils/browser-features";
import { handleGetPaymentUrlErrors } from "../../../../utils/error-utils/catch-error-handlers";
import { ComplianceError, InvalidPromotionCodeError } from "../../../../utils/error-utils/errors";
import { shouldShowComplianceErrorMessage } from "../../../../utils/locale-configuration-utils";
import { useResetPromotionCode } from "../../application/promotion-code-use-cases";

type HandleStripeCheckoutSessionHook = (
  country: CountryCode,
  setHasComplianceErrorMessage: Dispatch<SetStateAction<boolean>>,
  setHasPromotionCodeErrorMessage: Dispatch<SetStateAction<boolean>>,
  pageContext: PageId
) => Promise<void>;

export const useHandleStripeCheckoutSession = (): HandleStripeCheckoutSessionHook => {
  const getPaymentUrl = useGetPaymentUrl();
  const resetPromotionCode = useResetPromotionCode();

  return useCallback(
    async (
      country: CountryCode,
      setHasComplianceErrorMessage: Dispatch<SetStateAction<boolean>>,
      setHasPromotionCodeErrorMessage: Dispatch<SetStateAction<boolean>>,
      pageContext
    ): Promise<void> => {
      try {
        const { url } = await getPaymentUrl();
        const w = getWindow();

        if (w !== null) {
          w.location.href = url;
        }
      } catch (err) {
        handleGetPaymentUrlErrors(err as Error, pageContext);

        if (err instanceof ComplianceError && shouldShowComplianceErrorMessage(country)) {
          setHasComplianceErrorMessage(true);
        } else if (err instanceof InvalidPromotionCodeError) {
          setHasPromotionCodeErrorMessage(true);
          resetPromotionCode();
        }
      }
    },
    [getPaymentUrl, resetPromotionCode]
  );
};
