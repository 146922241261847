// extracted by mini-css-extract-plugin
export var card = "ShoppingCart-module--card--a8b75";
export var cardTitle = "ShoppingCart-module--cardTitle--04ce8";
export var dataProtectionLegalText = "ShoppingCart-module--dataProtectionLegalText--8ee5c";
export var errorMessage = "ShoppingCart-module--errorMessage--eddf5";
export var isDisabledSubmit = "ShoppingCart-module--isDisabledSubmit--3fe54";
export var priceSelected = "ShoppingCart-module--priceSelected--73ac7";
export var shoppingCart = "ShoppingCart-module--shoppingCart--00171";
export var shoppingCartButton = "ShoppingCart-module--shoppingCartButton--3ef8c";
export var shoppingCartExpandable = "ShoppingCart-module--shoppingCartExpandable--7e72f";
export var totalPrice = "ShoppingCart-module--totalPrice--f2984";