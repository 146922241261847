import { AffiliateLead, AffiliateLeadAdapter } from "../../domain/affiliate-lead/affiliate-lead";

const AFFILIATE_COOKIE_KEY = "affiliate_lead";
const COOKIE_MAX_AGE = 2600000;

const isBrowser = typeof document !== "undefined";

export const affiliateLeadAdapter = (): AffiliateLeadAdapter => {
  const save = (affiliateLead: AffiliateLead): void => {
    if (!isBrowser) {
      return;
    }

    document.cookie = `${AFFILIATE_COOKIE_KEY}=${JSON.stringify(
      affiliateLead
    )}; path=/; max-age=${COOKIE_MAX_AGE}`;
  };

  const get = (): AffiliateLead | null => {
    if (!isBrowser) {
      return null;
    }

    const cookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${AFFILIATE_COOKIE_KEY}=`));

    if (!cookie) {
      return null;
    }

    try {
      return JSON.parse(cookie.split("=")[1]) as AffiliateLead;
    } catch {
      return null;
    }
  };

  return {
    save,
    get,
  };
};
