export interface AffiliateLeadParams {
  utm_medium?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  compadoid?: string;
  clickId?: string;
  transaction_id?: string;
}

export interface AffiliateLead {
  utmCampaign?: string;
  utmContent?: string;
  utmSource?: string;
  clickId?: string;
}

export interface AffiliateLeadAdapter {
  save: (lead: AffiliateLead) => void;
  get: () => AffiliateLead | null;
}

type AffiliateLeadService = {
  areValidParams: (params: AffiliateLeadParams) => boolean;
  mapParamsToLead: (params: AffiliateLeadParams) => AffiliateLead;
};

export const affiliateLeadService = (): AffiliateLeadService => {
  const areValidParams = (params: AffiliateLeadParams): boolean => {
    if (!params.utm_medium) {
      return false;
    }

    const isAffiliate = params.utm_medium === "affiliate";
    const hasClickId = !!(params.compadoid ?? params.clickId ?? params.transaction_id);

    return isAffiliate && hasClickId;
  };

  const mapParamsToLead = (params: AffiliateLeadParams): AffiliateLead => {
    return {
      utmCampaign: params.utm_campaign,
      utmContent: params.utm_content,
      utmSource: params.utm_source,
      clickId: params.compadoid ?? params.clickId ?? params.transaction_id,
    };
  };

  return {
    areValidParams,
    mapParamsToLead,
  };
};
