// extracted by mini-css-extract-plugin
export var brand = "Navigation-module--brand--8075d";
export var expandable = "Navigation-module--expandable--ef46d";
export var expandableContent = "Navigation-module--expandableContent--33293";
export var icon = "Navigation-module--icon--dacac";
export var main = "Navigation-module--main--01f79";
export var mainNavigation = "Navigation-module--mainNavigation--7da59";
export var menuUser = "Navigation-module--menuUser--4686d";
export var navigation = "Navigation-module--navigation--89404";
export var navigationCta = "Navigation-module--navigationCta--85d84";
export var navigationFunnel = "Navigation-module--navigationFunnel--0fbc0";
export var navigationOnboarding = "Navigation-module--navigationOnboarding--7bb86";
export var navigationStuck = "Navigation-module--navigationStuck--e2881";
export var readMore = "Navigation-module--readMore--5db6a";