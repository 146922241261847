import {
  CalendarDate,
  fromDate,
  getLocalTimeZone,
  toCalendarDate,
  today,
  toZoned,
} from "@internationalized/date";
import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useState } from "react";
import * as Yup from "yup";

import Button, { ButtonVariantName } from "../../../../../../atoms/button/Button";
import PageStepForm from "../../../../../../organisms/page-step-form/PageStepForm";
import { Events, track } from "../../../../../../utils/analytics";
import { rollbar } from "../../../../../../utils/rollbar";
import DateFieldFormControl from "../../../../../quotes-funnel/ui/molecules/date-field-form-control/DateFieldFormControl";
import { useLead, useSetLead } from "../../../../application/lead-use-cases";
import { Lead } from "../../../../domain/lead";
import * as styles from "./Birthdate.module.scss";
import PartialBirthdate from "./PartialBirthdate";

interface PetBirthdateFormValues {
  pet_birth_date: CalendarDate;
}

interface Image {
  default: string;
}

const petBirthdateSVG: Image = require("../../../images/birthday.svg") as Image;

const petBirthdateSVGSrc: string = petBirthdateSVG.default;

const Birthdate = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const setLead = useSetLead();
  const initialBirthDateValue: CalendarDate | undefined = lead?.petBirthDate
    ? toCalendarDate(fromDate(lead.petBirthDate, getLocalTimeZone()))
    : undefined;
  const nextUrl = `/${i18n.language}/onboarding/breed/`;
  const localDate = today(getLocalTimeZone());
  const minValue = new CalendarDate(localDate.year, localDate.month, localDate.day).subtract({
    years: 100,
  });
  const [validateOnMount, setValidateOnMount] = useState(true);
  const [showPartialBirthdate, setShowPartialBirthdate] = useState(false);

  const handleShowPartialBirthdate = (): void => {
    track(Events.CLICKED_CTA, { eventSender: "Show Partial Birthdate CTA" });
    setShowPartialBirthdate(true);
  };

  const PetBirthdateFormData = {
    initialValues: {
      pet_birth_date: initialBirthDateValue,
    },
    validateOnMount,
    validationSchema: Yup.object({
      pet_birth_date: Yup.mixed()
        .required(t("common.validation.required"))
        .test(
          "validate-date",
          t("common.validation.valid_birth_date"),
          (value: CalendarDate): boolean => {
            if (!value) {
              return false;
            }

            const dateBeforeMinValue = value.compare(minValue) >= 0;
            const dateAfterMaxValue = value.compare(localDate) < 0;

            return !(!dateBeforeMinValue || !dateAfterMaxValue);
          }
        ),
    }),

    handleSubmit: (
      values: PetBirthdateFormValues,
      { setSubmitting }: FormikHelpers<PetBirthdateFormValues>
    ): void => {
      setSubmitting(true);

      setLead({
        petBirthDate: toZoned(values.pet_birth_date, "UTC").toDate(),
      })
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          setTimeout(() => navigate(nextUrl), 500);

          const answer = `${values.pet_birth_date.day}/${values.pet_birth_date.month}/${values.pet_birth_date.year}`;

          track(Events.FORM_ANSWERED, {
            question: "Pet birthdate",
            answer,
            isExactPetBirthdate: true,
          });
        })
        .catch(() => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<PetBirthdateFormValues>) => {
      const {
        isSubmitting,
        isValid,
        submitCount,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
      } = props;

      const handleClick = () => {
        setValidateOnMount(submitCount !== 0);
      };

      const handleBirthdate = (e: CalendarDate): void => {
        if (e && e.year < 1000) {
          return;
        }

        void setFieldTouched("pet_birth_date", true);
        void setFieldValue("pet_birth_date", e);
      };

      return (
        <Form className={cn(styles.petBirthdateForm)}>
          <div>
            <DateFieldFormControl
              name="pet_birth_date"
              defaultValue={initialBirthDateValue}
              onChange={(e: CalendarDate) => handleBirthdate(e)}
              label={t("onboarding.birthdate.date_field.label")}
            />
            {touched.pet_birth_date && errors.pet_birth_date && (
              <p className={styles.birthDateErrorMessage} role="alert" tabIndex={-1}>
                {errors.pet_birth_date as string}
              </p>
            )}
          </div>
          <Button
            type="button"
            onClick={handleShowPartialBirthdate}
            adoptionClassName={cn(styles.showPartialBirthdateButton)}
            variantName={ButtonVariantName.secondary}
          >
            {t("onboarding.birthdate.show_partial_birthdate.button.text")}
          </Button>

          <Button
            type="submit"
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            onClick={handleClick}
          >
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return showPartialBirthdate ? (
    <PartialBirthdate />
  ) : (
    <PageStepForm
      title={t("onboarding.birthdate.title")}
      subtitle={t("onboarding.birthdate.subtitle")}
      image={petBirthdateSVGSrc}
      formData={PetBirthdateFormData}
    />
  );
};
export default Birthdate;
