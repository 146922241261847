import { useTranslation } from "gatsby-plugin-react-i18next";
import { Dispatch, FormEvent, SetStateAction, useCallback, useState } from "react";

import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { Events, track } from "../../../../utils/analytics";
import { handleValidatePromotionCodeErrors } from "../../../../utils/error-utils/catch-error-handlers";
import {
  usePromotionCode,
  useResetPromotionCode,
  useValidatePromotionCode,
} from "../promotion-code-use-cases";

interface UsePromotionCodeForm {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  promotionCodeValue: string;
  setPromotionCodeValue: Dispatch<SetStateAction<string>>;
}

const errorMessages: Record<string, string> = {
  NotFoundError: "common.promotion_code.error",
  InvalidPromotionCodeError: "common.promotion_code.error.prior_transactions",
};

export const usePromotionCodeForm = (): UsePromotionCodeForm => {
  const { t } = useTranslation();
  const validatePromotionCode = useValidatePromotionCode();
  const result = useResult();
  const promotionCode = usePromotionCode();
  const resetPromotionCode = useResetPromotionCode();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [promotionCodeValue, setPromotionCodeValue] = useState<string>(promotionCode?.code || "");

  const handleError = useCallback(
    (error: Error): void => {
      handleValidatePromotionCodeErrors(error, "PromotionCodeForm");
      const errorKey = errorMessages[error.name] || "common.error_message";
      setErrorMessage(t(errorKey));
      track(Events.ERROR_OCCURRED, {
        question: "Promotion Code",
        error: error.name,
        answer: promotionCodeValue,
      });
    },
    [t, promotionCodeValue]
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();

      if (!result) {
        return;
      }

      if (promotionCode) {
        resetPromotionCode();
        setPromotionCodeValue("");
        track(Events.FORM_ANSWERED, {
          question: "Promotion Code",
          action: "Remove promotion code",
        });

        return;
      }

      try {
        setIsLoading(true);
        await validatePromotionCode(
          result.pet_parent_kb_key,
          result.country.toLowerCase(),
          promotionCodeValue
        );
        track(Events.FORM_ANSWERED, {
          question: "Promotion Code",
          answer: promotionCodeValue,
          action: "Promotion code applied",
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      validatePromotionCode,
      promotionCodeValue,
      promotionCode,
      resetPromotionCode,
      handleError,
      result,
    ]
  );

  return {
    isLoading,
    handleSubmit,
    errorMessage,
    setErrorMessage,
    promotionCodeValue,
    setPromotionCodeValue,
  };
};
