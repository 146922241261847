import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement } from "react";

import { countryDisclaimerLinks } from "../../../../../core/settings/country-disclaimer-links";
import { useTransformLinks } from "../../../../../core/utils/hooks/useTransformLinks";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { getDraftContractLink } from "../../../../../utils/draft-contract";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import * as styles from "./Disclaimer.module.scss";

const Disclaimer = (): ReactElement => {
  const { t } = useTranslation();
  const cart = useShoppingCart();
  const result = useResult();
  const healthProduct = cart.getSelectedHealthProduct();

  const countryLinks = countryDisclaimerLinks[result?.country || "DEFAULT"];
  const transformedLinks = useTransformLinks(countryLinks);

  if (healthProduct) {
    const healthQuote = result?.getQuote(healthProduct.id);
    const healthDraftContractLink = getDraftContractLink(healthQuote?.uuid);

    transformedLinks.healthDraftContract = `<a href="${healthDraftContractLink ?? "#"}">${t(
      "document.health.draft_contract.text"
    )}</a>`;
  }

  return (
    <span className={styles.disclaimer}>
      {parse(t("disclaimer.checkbox.label", transformedLinks))}
    </span>
  );
};

export default Disclaimer;
