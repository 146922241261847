import { PageId } from "./pages";

export enum CountryCode {
  DE = "DE",
  ES = "ES",
  FR = "FR",
  IT = "IT",
  PT = "PT",
}

export type Locale = "de" | "es" | "fr" | "it" | "pt";
export const availableLocales: Locale[] = ["de", "es", "fr", "it", "pt"];

export interface ContactMethodElement {
  destination: string;
  messageToShow?: string;
  phoneNumberToShow?: string;
  type: string;
}

export interface ZipCodeValidation {
  min: number;
  max: number;
}

export interface PhoneNumberLengthValidation {
  min: number;
  max: number;
}

export interface CountrySubsetConfiguration {
  countryCode: CountryCode;
  defaultLocale: Locale;
  name: string;
}

export interface CountryConfiguration extends CountrySubsetConfiguration {
  allowChangePolicyStartDate: boolean;
  alternativeHealthProductSelector: boolean;
  alternativeOnboardingEmailTitle: boolean;
  attributionQuestion: boolean;
  becasDiscount: boolean;
  chatLink: boolean;
  contactLinks: boolean;
  contactMethods: ContactMethodElement[];
  euFederLogos: boolean;
  exceptionStartingNumbersForWhatsApp: string[];
  fakeChipNumber: boolean;
  fcMediaTracking: boolean;
  glAddon: boolean;
  hasComplianceErrorMessage: boolean;
  hasDescriptionInAccessPage: boolean;
  hasTattooAnswer: boolean;
  hasWhatsAppConfirmationCheckbox: boolean;
  idDocumentRequired: boolean;
  invalidStartingNumbersForWhatsApp: string[];
  listTaxes: boolean;
  multiplePetsDiscount: number;
  pagesWithChatLink: PageId[];
  paymentMethods: string[];
  phoneNumberBeforePetParentPage: boolean;
  phoneNumberInPreQuotes: boolean;
  phoneNumberLength: PhoneNumberLengthValidation;
  phoneNumberPrefix: string;
  preContractNotificationIsRequired: boolean;
  preSalesMode: boolean;
  sanitizePhoneNumber: boolean;
  scheduleCallOption: boolean;
  selectableProductsDisclaimer: boolean;
  smallPrintPages: boolean;
  zipCode: ZipCodeValidation;
}

type CountryOverrides = Partial<Omit<CountryConfiguration, keyof CountrySubsetConfiguration>>;

const defaultCountryConfiguration: Omit<
  CountryConfiguration,
  "countryCode" | "defaultLocale" | "name"
> = {
  allowChangePolicyStartDate: true,
  alternativeHealthProductSelector: false,
  alternativeOnboardingEmailTitle: false,
  attributionQuestion: false,
  becasDiscount: true,
  chatLink: false,
  contactLinks: true,
  contactMethods: [],
  euFederLogos: false,
  exceptionStartingNumbersForWhatsApp: [],
  fakeChipNumber: true,
  fcMediaTracking: true,
  glAddon: false,
  hasComplianceErrorMessage: false,
  hasDescriptionInAccessPage: false,
  hasTattooAnswer: false,
  hasWhatsAppConfirmationCheckbox: false,
  idDocumentRequired: false,
  invalidStartingNumbersForWhatsApp: [],
  listTaxes: false,
  multiplePetsDiscount: 25,
  pagesWithChatLink: [],
  paymentMethods: ["card"],
  phoneNumberBeforePetParentPage: false,
  phoneNumberInPreQuotes: true,
  phoneNumberLength: { min: 9, max: 9 },
  phoneNumberPrefix: "",
  preContractNotificationIsRequired: false,
  preSalesMode: false,
  sanitizePhoneNumber: true,
  scheduleCallOption: false,
  selectableProductsDisclaimer: false,
  smallPrintPages: false,
  zipCode: { min: 5, max: 5 },
};

function createCountryConfiguration(
  subsetConfig: CountrySubsetConfiguration,
  overrides: CountryOverrides
): CountryConfiguration {
  return {
    ...defaultCountryConfiguration,
    ...overrides,
    ...subsetConfig,
  };
}

export const countries: Record<CountryCode, CountryConfiguration> = {
  DE: createCountryConfiguration(
    {
      countryCode: CountryCode.DE,
      defaultLocale: "de",
      name: "Deutschland",
    },
    {
      alternativeHealthProductSelector: true,
      alternativeOnboardingEmailTitle: true,
      chatLink: true,
      contactMethods: [
        {
          destination: "https://wa.me/message/AL336C7QS7K4G1?utm_term=maincta",
          type: "whatsapp",
          messageToShow: "common.whatsapp_contact.messageToShow",
        },
      ],
      exceptionStartingNumbersForWhatsApp: ["15", "16", "17"],
      hasTattooAnswer: true,
      hasWhatsAppConfirmationCheckbox: true,
      invalidStartingNumbersForWhatsApp: ["1", "2", "3"],
      listTaxes: true,
      multiplePetsDiscount: 50,
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
        PageId.landing,
      ],
      paymentMethods: ["card", "sepa_debit", "paypal"],
      phoneNumberLength: { min: 8, max: 12 },
      phoneNumberPrefix: "49",
      scheduleCallOption: true,
    }
  ),
  ES: createCountryConfiguration(
    {
      countryCode: CountryCode.ES,
      defaultLocale: "es",
      name: "España",
    },
    {
      allowChangePolicyStartDate: false,
      attributionQuestion: true,
      contactLinks: false,
      contactMethods: [
        {
          destination: "https://wa.me/message/Q5KWYO5LVFTYK1?utm_term=maincta",
          type: "whatsapp",
        },
      ],
      euFederLogos: true,
      fakeChipNumber: false,
      fcMediaTracking: false,
      glAddon: true,
      hasComplianceErrorMessage: true,
      idDocumentRequired: true,
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.smallPrint,
        PageId.preExistencesConditions,
        PageId.waitingPeriod,
        PageId.details,
        PageId.landing,
      ],
      phoneNumberBeforePetParentPage: true,
      phoneNumberInPreQuotes: false,
      phoneNumberPrefix: "34",
      smallPrintPages: true,
      sanitizePhoneNumber: false,
      selectableProductsDisclaimer: true,
    }
  ),
  FR: createCountryConfiguration(
    {
      countryCode: CountryCode.FR,
      defaultLocale: "fr",
      name: "France",
    },
    {
      contactMethods: [
        {
          destination: "tel:+33451420381",
          type: "phone",
          phoneNumberToShow: "04 51 42 03 81",
        },
        {
          destination: "https://wa.me/message/UC7OSSJPSYO3L1?utm_term=maincta",
          type: "whatsapp",
        },
      ],
      hasComplianceErrorMessage: true,
      hasDescriptionInAccessPage: true,
      hasWhatsAppConfirmationCheckbox: true,
      invalidStartingNumbersForWhatsApp: ["1", "2", "3", "4", "5"],
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
      ],
      phoneNumberLength: { min: 9, max: 10 },
      phoneNumberPrefix: "33",
      preContractNotificationIsRequired: true,
    }
  ),
  IT: createCountryConfiguration(
    {
      countryCode: CountryCode.IT,
      defaultLocale: "it",
      name: "Italia",
    },
    {
      contactMethods: [
        {
          destination: "tel:+39800829683",
          type: "phone",
          phoneNumberToShow: "800 829 683",
        },
        {
          destination: "https://wa.me/message/5CBJS2TOGOLWA1?utm_term=maincta",
          type: "whatsapp",
        },
      ],
      fcMediaTracking: false,
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
      ],
      phoneNumberLength: { min: 6, max: 12 },
      phoneNumberPrefix: "39",
      sanitizePhoneNumber: false,
    }
  ),
  PT: createCountryConfiguration(
    {
      countryCode: CountryCode.PT,
      defaultLocale: "pt",
      name: "Portugal",
    },
    {
      contactMethods: [
        {
          destination: "tel:+351308800505",
          type: "phone",
          phoneNumberToShow: "308 800 505",
        },
        {
          destination: "https://wa.me/message/YE6MEOBRSFTYB1?utm_term=maincta",
          type: "whatsapp",
        },
      ],
      fcMediaTracking: false,
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
      ],
      phoneNumberPrefix: "351",
      zipCode: { min: 8, max: 8 },
      sanitizePhoneNumber: false,
    }
  ),
};
