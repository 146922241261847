export class NotFoundError extends Error {
  constructor() {
    super("The requested resource could not be found");
    this.name = "NotFoundError";
  }
}

export class UnprocessableEntityError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class RecaptchaError extends UnprocessableEntityError {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class ComplianceError extends UnprocessableEntityError {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class InvalidPromotionCodeError extends UnprocessableEntityError {
  constructor(message: string) {
    super(message);
    this.name = "InvalidPromotionCodeError";
  }
}

export class ResultAlreadyPaidError extends UnprocessableEntityError {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class SetupIntentNotSucceededError extends UnprocessableEntityError {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class LeadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class InternalServerError extends Error {
  constructor() {
    super("An internal server error occurred");
    this.name = this.constructor.name;
  }
}

export class ServiceUnavailableError extends Error {
  constructor() {
    super("The service is currently unavailable");
    this.name = this.constructor.name;
  }
}

export class GatewayTimeoutError extends Error {
  constructor() {
    super("Gateway timed out");
    this.name = this.constructor.name;
  }
}

export class ResponseNotOkError extends Error {
  constructor(public status: number) {
    super(`HTTP status ${status}`);
    this.name = this.constructor.name;
  }
}

export class PaymentRequiredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class CardError extends PaymentRequiredError {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
