export const toSnakeCase = <T extends object>(obj: T): Record<string, unknown> => {
  const snakeCaseObj: Record<string, unknown> = {};
  const keys: string[] = Object.keys(obj);

  for (const key of keys) {
    const snakeCaseKey: string = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
    const value: unknown = (obj as Record<string, unknown>)[key];
    if (value && typeof value === "object" && !Array.isArray(value)) {
      snakeCaseObj[snakeCaseKey] = toSnakeCase(value);
    } else {
      snakeCaseObj[snakeCaseKey] = value;
    }
  }

  return snakeCaseObj;
};
