import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/400-italic.css";
import "@fontsource/nunito/700.css";

import cn from "classnames";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";

import BackgroundShape from "../atoms/background-shape/BackgroundShape";
import Icons from "../core/ui/images/icomoon/symbol-defs.svg";
import Footer from "../core/ui/organisms/footer/Footer";
import GoogleReviews from "../organisms/./google-reviews/GoogleReviews";
import AppReimbursement from "../organisms/app-reimbursement/app_reimbursement";
import CompanyValues from "../organisms/company-values/CompanyValues";
import Faqs from "../organisms/faqs/Faqs";
import Hero from "../organisms/hero/Hero";
import KspList from "../organisms/ksp-list/KspList";
import LandingBottomCta from "../organisms/landing-bottom-cta/LandingBottomCta";
import Meta from "../organisms/meta/meta";
import Navigation from "../organisms/navigation/Navigation";
import PageWrapper from "../organisms/page-wrapper/PageWrapper";
import ProductComparison from "../organisms/product-comparison/ProductComparison";
import withLandingWrapper from "../organisms/with_landing_wrapper";
import { CountryCode, Locale } from "../settings/countries";
import { getCurrentURLSearchParams } from "../utils/browser-features";
import useAxeptioScriptLoader from "../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../utils/locale-configuration-utils";
import * as styles from "./landing/Landing.module.scss";

const Landing = ({ data, location }) => {
  const {
    locale,
    slug,
    metaDescription,
    metaTitle,
    hero,
    productComparison,
    benefitsType,
    benefits,
    companyValues,
    appReimbursement,
    faqs,
    reviewsWidget,
    landingBottomCta,
    footer,
  } = data.contentfulMarketingPaidLandingLocal;

  const countryCodeIso = getCountryByLocale(locale);
  const { t, i18n } = useTranslation();

  const currentURLSearchParams = getCurrentURLSearchParams();
  const heroTitle = currentURLSearchParams?.get("title") || "";
  const hasAlternativeBenefitsSection = benefitsType === "alternative";

  void i18n.changeLanguage(locale);
  useAxeptioScriptLoader(locale);

  return (
    <>
      <Meta
        title={metaTitle}
        description={metaDescription}
        slug={slug}
        lang={locale as Locale}
        bodyClass={styles.landing}
        disableTranslations={false}
        noIndex={true}
        alternateVersions={undefined}
      />
      <PageWrapper>
        <Navigation location={location} countryCodeIso={countryCodeIso} />
        <main className={cn(styles.main, "or-main")}>
          <Hero
            data={{ ...hero, title: heroTitle }}
            countryCodeIso={countryCodeIso}
            adoptionClassName={styles.hero}
          />
          {productComparison && (
            <ProductComparison
              data={productComparison}
              adoptionClassName={styles.productComparison}
            />
          )}
          {benefits && (
            <KspList
              data={benefits}
              variantName={hasAlternativeBenefitsSection ? "alternativeBenefits" : "benefits"}
              adoptionClassName={styles.kspList}
            />
          )}
          {companyValues &&
            companyValues.map((companyValue) => (
              <CompanyValues
                key={companyValue.title}
                variantName={"designedByPetParents"}
                title={parse(companyValue.title)}
                subtitle={companyValue.subtitle}
              >
                <div>
                  <figure>
                    <BackgroundShape variantName="companyValues" />
                    <StaticImage
                      placeholder="blurred"
                      src={"../images/organisms/company-values/barkibu-dogs-and-humans.jpg"}
                      alt={t("landing.designed_by_petparents.image.1")}
                    />
                    <StaticImage
                      placeholder="blurred"
                      src={"../images/organisms/company-values/videocall-with-vet.jpg"}
                      alt={t("landing.designed_by_petparents.image.2")}
                    />
                  </figure>
                  <p>{parse(t("landing.designed_by_petparents.description.paragraphs.1"))}</p>
                  <p>{parse(t("landing.designed_by_petparents.description.paragraphs.2"))}</p>
                  <ul>
                    {[1, 2, 3, 4].map((i) => (
                      <li key={i}>
                        {parse(t(`landing.designed_by_petparents.description.list_items.${i}`))}
                      </li>
                    ))}
                  </ul>
                </div>
              </CompanyValues>
            ))}
          {appReimbursement && (
            <AppReimbursement data={appReimbursement} adoptionClassName={styles.appReimbursement} />
          )}
          {faqs && (
            <Faqs
              data={faqs}
              countryCodeIso={countryCodeIso as CountryCode}
              forceHideContactLinks={true}
            />
          )}
          {reviewsWidget && <GoogleReviews data={reviewsWidget} />}
          {landingBottomCta && (
            <LandingBottomCta
              data={landingBottomCta}
              countryCodeIso={countryCodeIso}
              adoptionClassName={styles.homeBottomCta}
            />
          )}
        </main>
        <Footer
          data={footer}
          countryCode={countryCodeIso as CountryCode}
          adoptionClassName={styles.footer}
        />
        <BackgroundShape />
        <Icons />
      </PageWrapper>
    </>
  );
};

export default withLandingWrapper(Landing);

export const query = graphql`
  query MarketingPaidLandingQuery($id: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulMarketingPaidLandingLocal(id: { eq: $id }) {
      slug
      locale: node_locale
      metaTitle
      metaDescription
      hero {
        ...HeroFragment
      }
      productComparison {
        ...ProductComparisonFragment
      }
      reviewsWidgetId
      benefitsType
      benefits {
        ...KspFragment
      }
      companyValues {
        title
        subtitle
      }
      appReimbursement {
        ...AppReimbursementFragment
      }
      faqs {
        ...FaqsFragment
      }
      reviewsWidget {
        ...reviewsWidgetFragment
      }
      landingBottomCta {
        ...LandingBottomCtaFragment
      }
      footer {
        ...FooterFragment
      }
    }
  }
`;
