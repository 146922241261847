// extracted by mini-css-extract-plugin
export var birthDateErrorMessage = "PetInfoForm-module--birthDateErrorMessage--3e194";
export var birthDateWarningMessage = "PetInfoForm-module--birthDateWarningMessage--01ed4";
export var birthdateWrapper = "PetInfoForm-module--birthdateWrapper--af27e";
export var breedSelect = "PetInfoForm-module--breedSelect--7703f";
export var cancel = "PetInfoForm-module--cancel--bffb3";
export var icon = "PetInfoForm-module--icon--d2a6d";
export var petInfoForm = "PetInfoForm-module--petInfoForm--d98fd";
export var petName = "PetInfoForm-module--petName--333c0";
export var save = "PetInfoForm-module--save--71b96";
export var sexSelect = "PetInfoForm-module--sexSelect--00808";
export var title = "PetInfoForm-module--title--2b9fb";