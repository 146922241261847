import { Events, track } from "../analytics";
import { rollbar } from "../rollbar";
import {
  ComplianceError,
  InvalidPromotionCodeError,
  NotFoundError,
  RecaptchaError,
  UnprocessableEntityError,
} from "./errors";

export const handleCreateResultErrors = (err: Error, context: string): void => {
  const description = "createResult";

  if (err instanceof RecaptchaError) {
    rollbar.warn(description, err, { fingerprint: "recaptcha-validation", context });
    track(Events.ERROR_OCCURRED, {
      error: "Invalid Recaptcha",
    });
  } else if (err instanceof UnprocessableEntityError) {
    rollbar.warn(description, err, {
      fingerprint: "create-result-unprocessable-entity",
      context,
      errorMessage: err.message,
      // Error message is sent as a custom attribute because original error message is truncated by Rollbar
    });
    track(Events.ERROR_OCCURRED, {
      error: `Error in ${description} in the ${context} page`,
    });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};

export const handleLeadErrors = (err: Error, context: string): void => {
  const description = "Lead is invalid to create a result";

  rollbar.warn(description, err, { context });

  track(Events.ERROR_OCCURRED, {
    error: description,
  });
};

export const handleNotifyPreContractErrors = (err: Error, context: string): void => {
  rollbar.warn(context, err, {
    fingerprint: "notify-pre-contract",
  });
};

export const handleSwitchToMonthlyErrors = (err: Error, context: string): void => {
  const description = "switchToMonthly";

  if (err instanceof UnprocessableEntityError) {
    rollbar.warn(description, err, {
      fingerprint: "switch-to-monthly-unprocessable-entity",
      context,
    });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};

export const handleUpdateResultErrors = (err: Error, context: string): void => {
  const description = "updateResult";

  if (err instanceof UnprocessableEntityError) {
    rollbar.warn(description, err, {
      fingerprint: "update-result-unprocessable-entity",
      context,
    });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};

export const handlePrepareForBindErrors = (err: Error, context: string): void => {
  const description = "prepareForBind";
  if (err instanceof UnprocessableEntityError) {
    rollbar.warn(description, err, {
      fingerprint: "prepare-for-bind-unprocessable-entity",
      context,
    });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};

export const handleGetPaymentUrlErrors = (err: Error, context: string): void => {
  const description = "getPaymentUrl";
  if (err instanceof ComplianceError) {
    rollbar.warn(description, err, { fingerprint: "compliance-validation", context });
  } else if (err instanceof InvalidPromotionCodeError) {
    rollbar.warn(description, err, { fingerprint: "promotion-code-not-valid", context });
  } else if (err instanceof UnprocessableEntityError) {
    rollbar.warn(description, err, {
      fingerprint: "get-payment-url-unprocessable-entity",
      context,
    });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};

export const handleGetResultErrors = (err: Error, context: string): void => {
  const description = "getResult";

  rollbar.error(err.message, err, { context: `${description}/${context}` });
};

export const handleValidatePromotionCodeErrors = (err: Error, context: string): void => {
  const description = "validatePromotionCode";

  if (err instanceof NotFoundError) {
    rollbar.info(description, err, { fingerprint: "promotion-code-not-found", context });
  } else if (err instanceof UnprocessableEntityError) {
    rollbar.info(description, err, { fingerprint: "promotion-code-not-valid", context });
  } else {
    rollbar.error(err.message, err, { context: `${description}/${context}` });
  }
};

export const handleFindUserErrors = (err: Error, context: string, userId: string): void => {
  const description = "findUser";

  if (err instanceof NotFoundError) {
    return;
  }

  rollbar.warn(err.message, err, {
    fingerprint: "analytics-find-user-error",
    context: `${description}/${context}`,
    userId,
  });
};
