import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import Button from "../../atoms/button/Button";
import RadioFieldset, {
  RadioFieldSetVariantsName,
} from "../../molecules/radio-fieldset/RadioFieldset";
import { Events, track } from "../../utils/analytics";
import useTrackPageViewed from "../../utils/hooks/useTrackPageViewed";
import { DealEventProperties } from "../deal-event-properties/domain/deal-event-properties";
import { useDealEventProperties } from "../deal-event-properties/use-cases/deal-event-properties-use-cases";
import PageStepForm from "../page-step-form/PageStepForm";
import { Result } from "../result/domain/result";
import { useResult } from "../result/use-cases/result-use-cases";
import * as styles from "./PreExistingConditions.module.scss";

interface RadioFieldSetOptions {
  title: string;
  value: string;
  labelVariantClassName?: string;
}

interface PreExistingConditionsFormValues {
  preExistingConditionsAnswer: string;
  preExistingConditionsCorrectAnswer: string;
}

interface Image {
  default: string;
}

const preExistingConditionsFormSVG: Image =
  require("../../images/pages/results/small-print/dogWithCollar.svg") as Image;

const preExisitingConditionsFormSVGSrc: string = preExistingConditionsFormSVG.default;

const PreExistingConditions = (): JSX.Element => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const [isSelectedCorrectAnswer, setIsSelectedCorrectAnswer] = useState<boolean | null>(null);
  const [radioFieldSetOptions, setRadioFieldSetOptions] = useState<RadioFieldSetOptions[]>();
  const nextUrl = `/results/${result?.uuid}/has_chip/`;
  const dealEventProperties: DealEventProperties | null = useDealEventProperties();

  useTrackPageViewed(Events.SMALL_PRINT_PRE_EXISTING_CONDITIONS_VIEWED_BROWSER);

  useEffect(() => {
    setRadioFieldSetOptions(() =>
      Array.from({ length: 2 }, (_, i) => {
        return {
          title: t(`small_print.pre_existing_conditions.answer_${i}`),
          value: `${i}`,
        };
      })
    );
  }, [t]);

  const getButtonLabel = (): string => {
    return isSelectedCorrectAnswer === null
      ? t("common.cta.next")
      : isSelectedCorrectAnswer
      ? t("common.cta.correct")
      : t("common.cta.try_again");
  };

  const trackAnswer = (answer: string, isCorrect: boolean): void => {
    const properties = {
      ...dealEventProperties,
      smallPrintPreExistingConditions: {
        answer,
        isSelectedCorrectAnswer: isCorrect,
      },
    };

    track(Events.FORM_ANSWERED, properties);
  };

  const preExisitingConditionsFormData = {
    initialValues: {
      preExistingConditionsAnswer: null,
      preExistingConditionsCorrectAnswer: "1",
    },
    validationSchema: Yup.object({
      preExistingConditionsAnswer: Yup.string().required(t("common.validation.required")),
    }),
    handleSubmit: (
      values: PreExistingConditionsFormValues,
      { setSubmitting }: FormikHelpers<PreExistingConditionsFormValues>
    ): void => {
      setSubmitting(true);

      const isCorrectAnswer: boolean =
        values.preExistingConditionsAnswer === values.preExistingConditionsCorrectAnswer;

      const newOptions: RadioFieldSetOptions[] | undefined = radioFieldSetOptions?.map(
        (option: RadioFieldSetOptions) => {
          if (option.value === values.preExistingConditionsAnswer) {
            return {
              ...option,
              labelVariantClassName: isCorrectAnswer ? "correctAnswer" : "incorrectAnswer",
            };
          }

          return option;
        }
      );

      const finishSubmit = (): void => {
        setRadioFieldSetOptions(newOptions);
        setIsSelectedCorrectAnswer(isCorrectAnswer);
        setSubmitting(false);
        trackAnswer(
          t(`small_print.pre_existing_conditions.answer_${values.preExistingConditionsAnswer}`),
          isCorrectAnswer
        );

        if (isCorrectAnswer) {
          setTimeout((): void => {
            void navigate(nextUrl);
          }, 500);
        }
      };

      setTimeout(finishSubmit, 500);
    },
    children: (props: FormikProps<PreExistingConditionsFormValues>) => {
      const { isSubmitting, values, setFieldValue } = props;

      return (
        <Form className={styles.preExistingConditionsForm}>
          {radioFieldSetOptions && (
            <RadioFieldset
              name={"pre-existing-conditions-question"}
              legend={"pre-existing-conditions-question"}
              initialValue={null}
              variantName={RadioFieldSetVariantsName.smallPrintPages}
              onChange={(value: string): void => {
                void setFieldValue("preExistingConditionsAnswer", value);
                if (isSelectedCorrectAnswer !== null) {
                  setIsSelectedCorrectAnswer(null);
                }
              }}
              options={radioFieldSetOptions}
            />
          )}
          <Button
            type="submit"
            disabled={
              isSubmitting ||
              !values.preExistingConditionsAnswer ||
              isSelectedCorrectAnswer === false
            }
            isLoading={isSubmitting}
            adoptionClassName={isSelectedCorrectAnswer ? styles.correctAnswer : ""}
          >
            {getButtonLabel()}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("small_print.pre_existing_conditions.title")}
      subtitle={t("small_print.pre_existing_conditions.subtitle")}
      image={preExisitingConditionsFormSVGSrc}
      formData={preExisitingConditionsFormData}
      adoptionClassName={styles.pageStepForm}
    />
  );
};

export default PreExistingConditions;
