import { useTranslation } from "gatsby-plugin-react-i18next";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";

import Heading from "../../../../../atoms/heading/Heading";
import RadioFieldset from "../../../../../molecules/radio-fieldset/RadioFieldset";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { Events, track } from "../../../../../utils/analytics";
import useVisibilityTracker from "../../../../../utils/hooks/useVisibilityTracker";
import { useTogglePaymentIntervalMonths } from "../../../application/shopping-cart-use-cases";
import PromotionCodeExpandable from "../promotion-code-expandable/PromotionCodeExpandable";
import * as styles from "./PaymentIntervalMonthsSelector.module.scss";

interface PaymentIntervalMonthsSelectorProps {
  isDisabled?: boolean;
  hasPaymentIntervalMonthsError?: boolean;
  setHasPaymentIntervalMonthsError: Dispatch<SetStateAction<boolean>>;
}

const PaymentIntervalMonthsSelector = ({
  isDisabled,
  hasPaymentIntervalMonthsError,
  setHasPaymentIntervalMonthsError,
}: PaymentIntervalMonthsSelectorProps): ReactElement => {
  const { ref } = useVisibilityTracker("payment-interval-months-selector", {
    threshold: 0.5,
  });
  const { t } = useTranslation();
  const result = useResult();
  const [selected, setSelected] = useState<string>("");
  const togglePaymentIntervalMonths = useTogglePaymentIntervalMonths(setSelected);

  const handleChangePartialPayment = (value: string): void => {
    togglePaymentIntervalMonths(value);
    setHasPaymentIntervalMonthsError(false);

    track(Events.TURNED_ON, {
      eventSender: "payment-interval-months-selector",
      selected: value === "1" ? "monthly" : "yearly",
    });
  };

  return (
    <>
      {result && (
        <section className={styles.paymentIntervalMonths} ref={ref}>
          <Heading level={2} adoptionClassName={styles.heading}>
            {t("payment_interval_months_selector.title")}
          </Heading>
          {hasPaymentIntervalMonthsError && (
            <p className={styles.errorMessage}>
              {t("pg_quotes.payment_interval_months_selector.error")}
            </p>
          )}
          <RadioFieldset
            name={"payment_interval_months"}
            legend={"payment_interval_months"}
            initialValue={selected}
            adoptionClassName={styles.radioFieldset}
            onChange={(value: string) => handleChangePartialPayment(value)}
            {...(isDisabled && { isDisabled })}
            options={[
              { title: t("common.monthly"), value: "1" },
              { title: t("common.yearly"), value: "12" },
            ]}
          />
          <PromotionCodeExpandable countryCodeIso={result.country} />
        </section>
      )}
    </>
  );
};

export default PaymentIntervalMonthsSelector;
