import { useTranslation } from "react-i18next";
import { SetterOrUpdater, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";

import { useAffiliateLead } from "../../../core/application/affiliate-lead-use-cases";
import { trackingParamsState } from "../../../core/state/analytics-state";
import { Locale } from "../../../settings/countries";
import { PetSpecies } from "../../../settings/pet";
import { getCountryByLocale } from "../../../utils/locale-configuration-utils";
import { Lead, LeadDTO, LeadValuesUpdatable } from "../domain/lead";
import { leadState } from "../state/lead-state";

export function useLead(): Lead {
  const lead: LeadDTO = useRecoilValue(leadState);
  const { i18n } = useTranslation();
  const petBirthDate = lead.petBirthDate && new Date(lead.petBirthDate);
  const countryIso = getCountryByLocale(i18n.language as Locale);

  return new Lead({
    ...lead,
    petBirthDate,
    countryIso,
  });
}

export function useSetLead(): (values: LeadValuesUpdatable) => Promise<Lead | undefined> {
  const leadSetter: SetterOrUpdater<LeadDTO> = useSetRecoilState(leadState);
  const affiliateLead = useAffiliateLead();

  return useRecoilCallback(
    ({ snapshot }) =>
      async (values: LeadValuesUpdatable): Promise<Lead | undefined> => {
        const trackingParams = await snapshot.getPromise(trackingParamsState);
        const currentLead: LeadDTO = await snapshot.getPromise(leadState);
        const newLead: LeadDTO = { ...currentLead, ...values, affiliateLead, ...trackingParams };

        leadSetter(newLead);

        return new Lead(newLead);
      },
    [leadSetter]
  );
}

export const useDiseasesList = (
  data: { node_locale: string; type: string; list: { title: string; diseaseId: string }[] }[],
  locale: string,
  species: PetSpecies | null
): { title: string; diseaseId: string }[] => {
  return data.find((item) => item.node_locale === locale && item.type === species)?.list ?? [];
};
