import { atom, DefaultValue, RecoilState, selector } from "recoil";

import { rollbar, RollbarLogArgument } from "../../../utils/rollbar";
import { HealthQuestionnaireDTO } from "../../health-questionnaire/domain/health-questionnaire";
import { getHealthQuestionnaire } from "../../health-questionnaire/repositories/health-questionnaire-repository";
import { currentResultUuidState } from "../../result/state/atoms";
import { DealEventPropertiesDTO } from "../domain/deal-event-properties";
import { getDealEventProperties } from "../repositories/deal-event-properties-repository";

export const dealEventPropertiesSelector: RecoilState<DealEventPropertiesDTO | null> =
  selector<DealEventPropertiesDTO | null>({
    key: "DealEventProperties",
    get: async ({ get }) => {
      const currentResultUuid: string | null = get(currentResultUuidState);

      if (!currentResultUuid) {
        return null;
      }

      try {
        const dealEventProperties: DealEventPropertiesDTO = await getDealEventProperties(
          currentResultUuid
        );
        const healthQuestionnaire: HealthQuestionnaireDTO | null = getHealthQuestionnaire();

        return {
          ...dealEventProperties,
          ...(healthQuestionnaire && { healthQuestionnaire }),
        } as DealEventPropertiesDTO;
      } catch (err) {
        if (err instanceof Error) {
          rollbar.error(err.message, err as RollbarLogArgument, {
            context: "getDealEventProperties",
          });
        }

        return null;
      }
    },
    set: ({ set }, newData: DealEventPropertiesDTO | DefaultValue | null): void => {
      set(dealEventPropertiesState, newData);
    },
  });

export const dealEventPropertiesState: RecoilState<DealEventPropertiesDTO | null> = atom({
  key: "DealEventPropertiesState",
  default: dealEventPropertiesSelector,
});
