import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Highlight, useHits, useSearchBox } from "react-instantsearch";

import Button from "../../../../../atoms/button/Button";
import { availableLocales, Locale } from "../../../../../settings/countries";
import { useBreedSelection } from "../../../application/breed-selection-use-cases";
import * as styles from "./AlternativeBreedSelect.module.scss";

interface BreedHitsProps {
  selectHit: (uuid: string, name: string) => void;
  formControlName: string;
}

interface AlgoliaHit {
  objectID: string;
  _highlightResult?: Record<string, any>;
  [key: string]: any;
}

interface BreedHit extends AlgoliaHit {
  key: string;
  uuid_key: string;
  __position: number;
}

const AlternativeBreedHits = ({ selectHit, formControlName }: BreedHitsProps): JSX.Element => {
  const [breedSelection] = useBreedSelection();
  const { i18n } = useTranslation();
  const language: Locale = availableLocales.includes(i18n.language as Locale)
    ? (i18n.language as Locale)
    : ("es" as Locale);
  const { hits } = useHits<BreedHit>();
  const { query } = useSearchBox();

  const filteredHits = hits.filter((hit) => query || !hit.key.startsWith("a_mongrel"));

  const handleClick = (hit: BreedHit): void => {
    selectHit(hit.uuid_key, hit[`name_${language}`]);
  };

  return (
    <div className={styles.hitsList}>
      {filteredHits.map((hit: BreedHit) => {
        const isSelectedHit = hit[`name_${language}`] === breedSelection[formControlName].name;
        const matchesBySynonym = (
          hit._highlightResult as Record<string, { matchLevel?: string }>
        )?.[`synonyms_${language}`]?.matchLevel?.match(/full|partial/);

        return (
          <Button
            key={hit.objectID}
            type="button"
            className={cn(styles.hit, { [styles.isSelectedHit]: isSelectedHit })}
            onClick={() => handleClick(hit)}
          >
            <div>
              <Highlight attribute={`name_${language}`} hit={hit} />
              {matchesBySynonym && <Highlight attribute={`synonyms_${language}`} hit={hit} />}
            </div>
            <svg className="icon icon--check" role="img" aria-hidden="true">
              <use xlinkHref="#icon-white-check"></use>
            </svg>
          </Button>
        );
      })}
    </div>
  );
};

export default AlternativeBreedHits;
