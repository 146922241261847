import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import Button from "../../../../../atoms/button/Button";
import { CountryCode } from "../../../../../settings/countries";
import { formatCurrency } from "../../../../../utils/currency";
import { usePromotionCodeForm } from "../../../application/hooks/usePromotionCodeForm";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import * as styles from "./PromotionCodeForm.module.scss";

interface PromotionCodeFormProps {
  countryCodeIso: CountryCode;
  adoptionClassName?: string;
  showDiscountApplied?: boolean;
}

const PromotionCodeForm = ({
  adoptionClassName,
  showDiscountApplied = true,
}: PromotionCodeFormProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const cart = useShoppingCart();
  const promotionCode = usePromotionCode();
  const {
    isLoading,
    handleSubmit,
    errorMessage,
    setErrorMessage,
    promotionCodeValue,
    setPromotionCodeValue,
  } = usePromotionCodeForm();

  const language = i18n.language;
  const isDisabled = promotionCodeValue.trim().length === 0;

  return (
    <>
      <form className={cn(styles.promotionCodeForm, adoptionClassName)} onSubmit={handleSubmit}>
        <input
          value={promotionCodeValue}
          onChange={(e) => {
            setPromotionCodeValue(e.target.value);
            setErrorMessage("");
          }}
          className={styles.promotionCodeField}
          name={"promotionCode"}
          type="text"
          placeholder={t("pg_quotes.promotion_code.placeholder")}
          {...(promotionCode && { disabled: true })}
        />
        <Button
          type="submit"
          adoptionClassName={cn(styles.promotionCodeButton, {
            [styles.hasCodeApplied]: promotionCode,
            [styles.isDisabled]: isDisabled,
          })}
          isLoading={isLoading}
          disabled={isDisabled}
        >
          {!promotionCode ? (
            <>{t("common.apply")}</>
          ) : (
            <>
              {showDiscountApplied ? (
                <span>{`${t("common.applied")} (-${formatCurrency(
                  cart.discount,
                  language,
                  cart.currency
                )})`}</span>
              ) : (
                <span>{t("common.applied")}</span>
              )}
              <svg className={styles.iconTrash} aria-hidden="true">
                <use href="#icon-trash" />
              </svg>
            </>
          )}
        </Button>
      </form>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </>
  );
};

export default PromotionCodeForm;
