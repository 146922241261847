import { CountryCode } from "../../../settings/countries";
import { HealthQuestionnaireDTO } from "../../health-questionnaire/domain/health-questionnaire";

interface FunnelValuesElement {
  fulfilled: number;
  people_new: number;
  quotes_displayed: number;
}

interface FunnelValues {
  fb: FunnelValuesElement;
  gads: FunnelValuesElement;
}

interface CartItems {
  id: string;
  item_price: number;
  quantity: number;
}

interface Deal {
  amount: number;
  cfPetKbKey: string;
  cfPetParentKbKey: string;
  cfProducts: string;
  cfUrlToBuy: string;
  name: string;
  petName: string;
}

interface Traits {
  cfKbKey: string;
  country: CountryCode;
  deal: Deal;
  dealAmount: number;
  dealContinuePurchaseUrl: string;
  dealContracts?: string;
  dealProducts: string;
  email: string;
  externalId: string;
  payment_interval_months: number;
  policy_start_date: string;
  is_pre_activated_client: boolean;
}

export interface DealEventPropertiesDTO {
  continuePurchaseUrl: string;
  currency: string;
  event_timestamp: number;
  funnel_values: FunnelValues | Record<PropertyKey, never>;
  items: CartItems[];
  page: string;
  products: string;
  selected_product_ids: string;
  traits: Traits;
  transaction_id: string;
  value: number;
  healthQuestionnaire: HealthQuestionnaireDTO;
}

export class DealEventProperties implements DealEventPropertiesDTO {
  readonly continuePurchaseUrl: string;
  readonly currency: string;
  readonly event_timestamp: number;
  readonly funnel_values: FunnelValues | Record<PropertyKey, never>;
  readonly items: CartItems[];
  readonly page: string;
  readonly products: string;
  readonly selected_product_ids: string;
  readonly traits: Traits;
  readonly transaction_id: string;
  readonly value: number;
  readonly healthQuestionnaire: HealthQuestionnaireDTO;

  constructor(attrs: DealEventPropertiesDTO) {
    Object.assign(this, attrs);
  }
}
