// extracted by mini-css-extract-plugin
export var aboutUsBottomCta = "AboutUsLanding-module--aboutUsBottomCta--d0f80";
export var aboutUsLanding = "AboutUsLanding-module--aboutUsLanding--519c6";
export var cta = "AboutUsLanding-module--cta--a4526";
export var employeeCarousel = "AboutUsLanding-module--employeeCarousel--1a6a5";
export var heroAboutUs = "AboutUsLanding-module--heroAboutUs--e198a";
export var heroLogo = "AboutUsLanding-module--heroLogo--c6c86";
export var main = "AboutUsLanding-module--main--3cefb";
export var numbers = "AboutUsLanding-module--numbers--419c3";
export var ourHistory = "AboutUsLanding-module--ourHistory--72f46";
export var ourHistoryDescription = "AboutUsLanding-module--ourHistoryDescription--397c6";
export var ourHistoryImage = "AboutUsLanding-module--ourHistoryImage--73f9c";
export var ourValues = "AboutUsLanding-module--ourValues--9aa57";
export var pageWrapper = "AboutUsLanding-module--page-wrapper--9a8ef";
export var sectionTitle = "AboutUsLanding-module--sectionTitle--732a6";
export var whoAreWe = "AboutUsLanding-module--whoAreWe--5e380";