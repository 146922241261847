import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement } from "react";

import BackgroundShape from "../../../../../atoms/background-shape/BackgroundShape";
import Icons from "../../../../../core/ui/images/icomoon/symbol-defs.svg";
import Header from "../../../../../core/ui/molecules/header/Header";
import Faqs from "../../../../../organisms/faqs/Faqs";
import Hero from "../../../../../organisms/hero/Hero";
import KspList from "../../../../../organisms/ksp-list/KspList";
import Meta from "../../../../../organisms/meta/meta";
import PageWrapper from "../../../../../organisms/page-wrapper/PageWrapper";
import { Locale } from "../../../../../settings/countries";
import { Events, track } from "../../../../../utils/analytics";
import useAxeptioScriptLoader from "../../../../../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../../../../../utils/locale-configuration-utils";
import { ApplePayLandingLocal } from "../../../utils/contentful-types";
import FooterApplePay from "../../molecules/footer/FooterApplePay";
import * as styles from "./ApplePay.module.scss";

const ApplePay = ({ data }: ApplePayLandingLocal): ReactElement => {
  const { locale, slug, metaDescription, metaTitle, hero, benefits, faqs, footerTradeMark } =
    data.contentfulApplePayLandingLocal;
  const alternateVersions = data.alternateVersions.nodes
    .map(({ localizedContent }) => localizedContent)
    .map((item) => ({
      id: item.id,
      slug: `${item.locale}/${item.slug}`,
      locale: item.locale,
    }));

  const { i18n } = useTranslation();
  const countryCodeIso = getCountryByLocale(locale as Locale);

  void i18n.changeLanguage(locale);
  useAxeptioScriptLoader(locale);

  track(Events.APPLE_PAY_VIEWED_BROWSER);

  return (
    <>
      <Meta
        alternateVersions={alternateVersions}
        title={metaTitle}
        description={metaDescription}
        slug={slug}
        lang={locale as Locale}
        bodyClass={styles.applePayLanding}
        disableTranslations={false}
      />
      <PageWrapper>
        <Header countryCodeIso={countryCodeIso} />
        <main className={cn(styles.main, "or-main")}>
          {hero && (
            <Hero data={hero} countryCodeIso={countryCodeIso} adoptionClassName={styles.hero} />
          )}
          {benefits && <KspList data={benefits} adoptionClassName={styles.kspList} />}
          {faqs && (
            <Faqs data={faqs} countryCodeIso={countryCodeIso} forceHideContactLinks={true} />
          )}
        </main>
        <FooterApplePay tradeMark={footerTradeMark.internal.content} />
        <BackgroundShape adoptionClassName={styles.backgroundShape} />
        <Icons />
      </PageWrapper>
    </>
  );
};

export default ApplePay;
