import { CalendarDate, createCalendar } from "@internationalized/date";
import cn from "classnames";
import { useField } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useRef, useState } from "react";
import { useDateField } from "react-aria";
import { useDateFieldState } from "react-stately";

import DateSegment from "../../atoms/date-segment/DateSegment";
import * as styles from "./DateFieldFormControl.module.scss";

interface DateFieldFormControlProps {
  name: string;
  defaultValue: CalendarDate | undefined;
  minValue?: CalendarDate;
  maxValue?: CalendarDate;
  label: string;
  onChange: (e: CalendarDate) => void;
}

const DateFieldFormControl = (props: DateFieldFormControlProps): ReactElement => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });
  const ref = useRef(null);
  const { labelProps, fieldProps } = useDateField(props, state, ref);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [, , helpers] = useField(props.name);
  const segmentsToValidate = state.segments.filter((item) => item.isEditable);

  const handleBlur = (): void => {
    setIsFocused(false);

    if (segmentsToValidate.every((item) => item.isPlaceholder)) {
      void helpers.setTouched(true);
    }
  };

  return (
    <div>
      <div className={cn(styles.dateFieldFormControl, { [styles.isFocused]: isFocused })}>
        <span {...labelProps} className={styles.label}>
          {props.label}
        </span>
        <div {...fieldProps} ref={ref} className={styles.field} onBlur={handleBlur}>
          {state.segments.map((segment, i) => (
            <DateSegment
              key={i}
              segment={segment}
              state={state}
              handleFocus={() => setIsFocused(true)}
              handleBlur={() => setIsFocused(false)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DateFieldFormControl;
