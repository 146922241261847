// extracted by mini-css-extract-plugin
export var alternativeFooter = "Footer-module--alternativeFooter--73d6e";
export var contactLinks = "Footer-module--contactLinks--76b82";
export var contactLinksWrapper = "Footer-module--contactLinksWrapper--73664";
export var contactWrapper = "Footer-module--contactWrapper--96c6d";
export var country = "Footer-module--country--211e3";
export var countrySwitch = "Footer-module--countrySwitch--1d841";
export var disclaimers = "Footer-module--disclaimers--30978";
export var emailWrapper = "Footer-module--emailWrapper--c4d50";
export var euFederLogos = "Footer-module--euFederLogos--ec0f5";
export var euFederLogosList = "Footer-module--euFederLogosList--c42e8";
export var footer = "Footer-module--footer--9738a";
export var list = "Footer-module--list--91f86";
export var logoWrapper = "Footer-module--logoWrapper--f108c";
export var resources = "Footer-module--resources--58d75";
export var scheduleCallWrapper = "Footer-module--scheduleCallWrapper--a88a7";
export var sectionTitle = "Footer-module--sectionTitle--a7349";
export var social = "Footer-module--social--012ce";
export var withSocialLogos = "Footer-module--withSocialLogos--e4f5b";