import cn from "classnames";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement, useEffect, useState } from "react";

import Icons from "../../core/ui/images/icomoon/symbol-defs.svg";
import ConnectionLost from "../../images/pages/leads/connection-lost.svg";
import PageTitle from "../../molecules/page-title/PageTitle";
import Footer from "../../organisms/footer/AlternativeFooter";
import Meta from "../../organisms/meta/meta";
import Navigation from "../../organisms/navigation/Navigation";
import PageWrapper from "../../organisms/page-wrapper/PageWrapper";
import { ResultDTO } from "../../organisms/result/domain/result";
import { useCreateResult } from "../../organisms/result/use-cases/result-use-cases";
import withLandingWrapper from "../../organisms/with_landing_wrapper";
import { CountryCode } from "../../settings/countries";
import { getCurrentURLSearchParams } from "../../utils/browser-features";
import { handleCreateResultErrors } from "../../utils/error-utils/catch-error-handlers";
import { getLocaleByCountry, homePath } from "../../utils/locale-configuration-utils";
import { getAllUtmsPrefixed } from "../../utils/utm";
import * as styles from "./new.module.scss";

const NewLead = (): ReactElement => {
  const countryCodeIso =
    (getCurrentURLSearchParams()?.get("country")?.toUpperCase() as CountryCode) ?? CountryCode.ES;
  const locale = getLocaleByCountry(countryCodeIso);
  const createResult = useCreateResult();
  const { i18n, t } = useTranslation();
  const [errorOnCreateResult, setErrorOnCreateResult] = useState<boolean>(false);

  useEffect((): void => {
    const currentURLSearchParams = getCurrentURLSearchParams();
    const noEditable = currentURLSearchParams?.get("no_editable");
    const petKbKey = currentURLSearchParams?.get("pet_kb_key");
    const productKbKey = currentURLSearchParams?.get("product_kb_key");
    const policyStartDate = currentURLSearchParams?.get("policy_start_date");

    if (!petKbKey) {
      void navigate(`${homePath(countryCodeIso)}/onboarding/species/`);

      return;
    }

    const resultValues = {
      ...getAllUtmsPrefixed(),
      pet_kb_key: petKbKey,
      ...(policyStartDate && { policy_start_date: policyStartDate }),
      ...(productKbKey && { product_kb_key: productKbKey }),
    };

    createResult(resultValues)
      .then((result: ResultDTO): void => {
        if (!result) {
          return;
        }

        void navigate(`/results/${result.uuid}/quoting/${noEditable ? "?no_editable=1" : ""}`);
      })
      .catch((err: Error): void => {
        handleCreateResultErrors(err, "leads/new/petKbKey");
        setErrorOnCreateResult(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): void => {
    void i18n.changeLanguage(locale);
  }, [i18n, locale]);

  return errorOnCreateResult ? (
    <PageWrapper>
      <Meta
        lang={locale}
        bodyClass={cn(styles.newLead, { [styles.errorOnCreateResult]: errorOnCreateResult })}
        disableTranslations={true}
        slug={undefined}
        description={undefined}
        noIndex={true}
        title={t("common.page.title")}
        alternateVersions={undefined}
      />
      <Navigation countryCodeIso={countryCodeIso} />
      <main id="main" className={styles.main}>
        <section className={styles.errorOnCreateResultWrapper}>
          <ConnectionLost />
          <PageTitle
            title={t("pg_leads_new.create_result_error.title")}
            subtitle={parse(t("pg_leads_new.create_result_error.subtitle"))}
            adoptionClassName={styles.errorOnCreateResultHeader}
          />
        </section>
      </main>
      <Footer />
      <Icons />
    </PageWrapper>
  ) : (
    <></>
  );
};

export default withLandingWrapper(NewLead);

export const query = graphql`
  query NewLeadQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
