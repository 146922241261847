import { useCallback } from "react";
import { SetterOrUpdater, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { currentResultUuidState } from "../../result/state/atoms";
import { DealEventProperties, DealEventPropertiesDTO } from "../domain/deal-event-properties";
import { getDealEventProperties } from "../repositories/deal-event-properties-repository";
import { dealEventPropertiesState } from "../state/atoms";

export function useDealEventProperties(): DealEventProperties | null {
  const deal = useRecoilValueLoadable(dealEventPropertiesState);

  switch (deal.state) {
    case "hasValue":
      if (!deal.contents) {
        return null;
      }

      return new DealEventProperties(deal.contents);
    case "hasError":
      throw deal.contents;
    default:
      return null;
  }
}

export function useGetDealEventProperties(): (() => Promise<DealEventPropertiesDTO>) | undefined {
  const resultUuid: string | null = useRecoilValue(currentResultUuidState);

  if (!resultUuid) {
    return;
  }

  return async (): Promise<DealEventPropertiesDTO> => await getDealEventProperties(resultUuid);
}

export function useSetDealEventProperties(): (dealEventProperties: DealEventPropertiesDTO) => void {
  const setDealEventProperties: SetterOrUpdater<DealEventPropertiesDTO> =
    useSetRecoilState(dealEventPropertiesState);

  return useCallback(
    (dealEventProperties: DealEventPropertiesDTO) => setDealEventProperties(dealEventProperties),
    [setDealEventProperties]
  );
}
