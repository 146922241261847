// extracted by mini-css-extract-plugin
export var correctAnswer = "RadioFieldset-module--correctAnswer--71057";
export var description = "RadioFieldset-module--description--b7f04";
export var healthProductSelector = "RadioFieldset-module--healthProductSelector--a67e4";
export var icon = "RadioFieldset-module--icon--f5129";
export var image = "RadioFieldset-module--image--9219a";
export var incorrectAnswer = "RadioFieldset-module--incorrectAnswer--a814a";
export var petSpeciesSelector = "RadioFieldset-module--petSpeciesSelector--e0762";
export var radioFieldset = "RadioFieldset-module--radioFieldset--df511";
export var smallPrintPages = "RadioFieldset-module--smallPrintPages--099bb";
export var subtitle = "RadioFieldset-module--subtitle--2ad28";
export var title = "RadioFieldset-module--title--ef0f7";